import { ErrorBoundary } from 'react-error-boundary'
import { RouteObject } from 'react-router-dom'

import { TooManyRequests } from '~components/ErrorPages'
import { Footer } from '~components/Footer'
import { FourOhFour } from '~components/FourOhFour'
import { ScrollToTop } from '~components/ScrollToTop'

import PrivacyPolicy from '~features/common/PrivacyPolicy'
import { TermsOfUsePage } from '~features/common/TermsOfUse'
import { EventFormPage } from '~features/form/pages/EventFormPage'
import { PublicEventListPage } from '~features/form/pages/PublicEventListPage'
import { SgidEventCallbackPage } from '~features/form/pages/SgidEventCallbackPage'

export const userRoutes: RouteObject[] = [
  {
    path: '/:eventId',
    element: (
      <ScrollToTop>
        <ErrorBoundary fallback={<FourOhFour />}>
          <EventFormPage />
          <Footer />
        </ErrorBoundary>
      </ScrollToTop>
    ),
  },
  {
    path: '/l/:eventListId',
    element: (
      <ScrollToTop>
        <ErrorBoundary fallback={<FourOhFour />}>
          <PublicEventListPage />
          <Footer />
        </ErrorBoundary>
      </ScrollToTop>
    ),
  },
  {
    path: '/privacy',
    element: (
      <ScrollToTop>
        <PrivacyPolicy />
      </ScrollToTop>
    ),
  },
  {
    path: '/terms',
    element: (
      <ScrollToTop>
        <TermsOfUsePage />
      </ScrollToTop>
    ),
  },
  {
    path: '/too-many-requests',
    element: (
      <ScrollToTop>
        <TooManyRequests />
      </ScrollToTop>
    ),
  },
  {
    path: '/sgid-event-callback',
    element: <SgidEventCallbackPage />,
  },
]
