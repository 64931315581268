import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { RestrictedFooter } from '@opengovsg/design-system-react'

import { APP_NAME } from '~/constants/config'

const FOOTER_LINKS = [
  {
    label: 'Privacy',
    href: '/privacy',
  },
  {
    label: 'Terms of Use',
    href: '/terms',
  },
  {
    label: 'Report Vulnerability',
    href: 'https://www.tech.gov.sg/report_vulnerability',
  },
]

const ADMIN_FORM_LINK = 'https://form.gov.sg/63fc4348fff5120012b6cf28'
const ADMIN_FORM_URL_FIELD_ID = '651e8d427398ef001161e5a5'

const PUBLIC_FORM_LINK = 'https://form.gov.sg/64c72b3214d8a40012791b17'
const PUBLIC_FORM_URL_FIELD_ID = '64c72c1314d8a40012794ca1'

export const Footer = () => {
  const { pathname, search } = useLocation()
  const feedbackUrl = useMemo(() => {
    // Trailing slash to guard against the off-chance that we have some
    // nanoid that starts with admin. We dont render the Footer on the
    // login page, but we still include it in the check to avoid surprises
    // if we ever do render it there.
    const isAdminRoute =
      pathname.startsWith('/admin/') && pathname !== '/admin/login'

    const formLink = isAdminRoute ? ADMIN_FORM_LINK : PUBLIC_FORM_LINK
    const urlFieldId = isAdminRoute
      ? ADMIN_FORM_URL_FIELD_ID
      : PUBLIC_FORM_URL_FIELD_ID

    return `${formLink}?${urlFieldId}=${encodeURIComponent(
      window.location.href,
    )}`

    // We want to update on both path change and query string change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search])
  return (
    <RestrictedFooter
      appLink={window.location.origin}
      appName={APP_NAME.full}
      containerProps={{
        bgColor: 'base.canvas.alt',
      }}
      footerLinks={[
        {
          label: 'Feedback',
          href: feedbackUrl,
        },
        ...FOOTER_LINKS,
      ]}
    />
  )
}
