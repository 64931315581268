import { forwardRef, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile'
import { FormErrorMessage, useToast } from '@opengovsg/design-system-react'

import { TURNSTILE_ACTION } from '~shared/constants'

import { TURNSTILE_SITE_KEY } from '~constants/config'

export const TURNSTILE_TOKEN_FIELD_NAME = 'CALSG_TURNSTILE_TOKEN'

export const TURNSTILE_DEFAULT_ERROR_MESSAGE =
  'Sorry, something went wrong when verifying your response. Please wait a few seconds and try again.'

export const TurnstileField = forwardRef<TurnstileInstance>((_props, ref) => {
  const {
    formState: { errors, isSubmitted },
  } = useFormContext()
  const [
    isTurnstileWaitingForInteraction,
    setIsTurnstileWaitingForInteraction,
  ] = useState(false)
  const [isTurnstileSuccess, setIsTurnstileSuccess] = useState(false)
  const [hasErrorToastShown, setHasErrorToastShown] = useState(false)
  const toast = useToast()

  /* There are 3 scenarios where validation might fail:
   * 1. User clicks submit before Turnstile even loads. Here we use this effect to show
   * an error toast.
   * 2. User clicks submit while Turnstile is non-interactively waiting for a response
   * in the background. Similarly we use this effect to show an error toast.
   * 3. User clicks submit while Turnstile checkbox is showing, but user has not checked
   * it yet. Here we show the FormErrorMessage as it is located right after the checkbox.
   */
  useEffect(() => {
    if (
      isSubmitted &&
      !isTurnstileSuccess &&
      !isTurnstileWaitingForInteraction &&
      !hasErrorToastShown
    ) {
      toast({
        status: 'error',
        description:
          'You seem to have submitted too quickly. Please wait a few seconds and try again.',
      })
      setHasErrorToastShown(true)
    }
  }, [
    hasErrorToastShown,
    isSubmitted,
    isTurnstileSuccess,
    isTurnstileWaitingForInteraction,
    toast,
  ])

  return (
    <FormControl isInvalid={!!errors[TURNSTILE_TOKEN_FIELD_NAME]} mt={0}>
      <Controller
        name={TURNSTILE_TOKEN_FIELD_NAME}
        rules={{
          required: 'Please check the box above for security verification.',
        }}
        render={({ field }) => (
          <Turnstile
            ref={ref}
            siteKey={TURNSTILE_SITE_KEY}
            options={{
              theme: 'light',
              appearance: 'interaction-only',
              action: TURNSTILE_ACTION,
            }}
            scriptOptions={{
              crossOrigin: 'anonymous',
            }}
            onSuccess={(token) => {
              field.onChange(token)
              setIsTurnstileWaitingForInteraction(false)
              setIsTurnstileSuccess(true)
            }}
            // Library type for onError is wrong, Cloudflare docs say that
            // onError is called with the error code
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onError={(error: unknown) => {
              console.error('Turnstile error', error)
              toast({
                status: 'error',
                description: TURNSTILE_DEFAULT_ERROR_MESSAGE,
              })
              return true
            }}
            onExpire={() => {
              field.onChange('')
            }}
            onBeforeInteractive={() => {
              setIsTurnstileWaitingForInteraction(true)
            }}
            onAfterInteractive={() => {
              setIsTurnstileWaitingForInteraction(false)
            }}
            onUnsupported={() => {
              console.error('Turnstile error: Unsupported browser')
              toast({
                status: 'error',
                description:
                  "Sorry, our system doesn't support your web browser or device. Please use another web browser or device.",
              })
            }}
            style={{
              alignSelf: 'start',
              width: '100%',
              overflowX: 'auto',
            }}
          />
        )}
      />
      {isTurnstileWaitingForInteraction && (
        <FormErrorMessage>
          {errors[TURNSTILE_TOKEN_FIELD_NAME]?.message as string}
        </FormErrorMessage>
      )}
    </FormControl>
  )
})

TurnstileField.displayName = 'TurnstileField'
